define("iris/routes/human-resource-management/resource-planner", ["exports", "ember-concurrency", "iris/mixins/protected-route"], function (_exports, _emberConcurrency, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourcePlannerRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ResourcePlannerRoute extends Ember.Route.extend(_protectedRoute.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor2, this);

      _defineProperty(this, "rpPriorities", null);

      _defineProperty(this, "period", null);
    }

    async model(params) {
      if (!params.id) {
        return;
      }

      const currentUser = await this.sessionAccount.account;
      let rpPriorities = this.rpPriorities;

      if (!rpPriorities) {
        rpPriorities = this.store.findAll('resourcePlanPriority');
        this.set('rpPriorities', rpPriorities);
      }

      let period = this.period;

      if (!period || period.get('id') !== params.id) {
        const periodResult = await this.store.query('resourcePeriod', {
          filter: {
            where: {
              id: params.id
            },
            include: ['state', 'resourceGroup']
          }
        });
        period = periodResult.get('firstObject');
        this.set('period', period);
      }

      let publicHolidays = this.publicHolidays;

      if (!publicHolidays) {
        publicHolidays = await this.store.query('publicHoliday', {
          filter: {
            where: {
              date: {
                between: [moment(period.startDate).subtract(1, 'd'), moment(period.endDate).add(1, 'd')]
              }
            },
            order: 'date'
          }
        });
        this.set('publicHolidays', publicHolidays);
      }

      const plansResult = await this.store.query('resourcePlan', {
        filter: {
          where: {
            resourcePeriodId: params.id
          }
        }
      });
      const resourceGroup = await period.get('resourceGroup');
      const solvers = await resourceGroup.get('solvers');
      let columns = await this.ajax.call('GET', 'resourcePeriod', period.get('id'), period.get('isClosed') ? 'getDataForClosedPeriod' : 'getDataForPlanning', {}, true);
      columns.forEach(column => {
        column.cards.forEach(card => {
          card.isCompact = true;
        });
        column.stickyCards.forEach(sticky => {
          sticky.isCompact = true;
        });
      });
      return (0, _emberConcurrency.hash)({
        period,
        columns,
        rpPriorities,
        currentUser,
        publicHolidays
      });
    }

    didTransition() {
      this.set('period', null);
      return true; // Bubble the didTransition event
    }

    async refreshModel() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class));
  _exports.default = ResourcePlannerRoute;
});