define("iris/components/issue-report-edit/entity-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Aq9uQVZ",
    "block": "{\"symbols\":[\"entity\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6 border-gray-200 dark:border-gray-500 bg-gray-50 dark:bg-portoDark-gray-2e353e\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-bold text-3xl mb-6\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"incident-form.ci-links\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"entities\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"text-2xl grid grid-cols-2 pb-6 gap-6\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"truncate\"],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"link\"],null],false],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@target\",\"@models\"],[\"cmdb.entity-types.entities.entity.attributes\",\"_blank\",[28,\"array\",[[23,1,[\"entityType\",\"id\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"siteZone\",\"remoteAccessLink\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"truncate\"],[11,\"title\",[23,0,[\"plannedCost\"]]],[8],[0,\"\\n          \"],[1,[28,\"fa-icon\",[\"external-link\"],null],false],[0,\"\\n          \"],[7,\"a\",true],[11,\"href\",[23,1,[\"siteZone\",\"remoteAccessLink\"]]],[10,\"target\",\"_blank\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"site-zone.remoteAccessLink\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/issue-report-edit/entity-links/template.hbs"
    }
  });

  _exports.default = _default;
});