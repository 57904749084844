define("iris/controllers/projects/files/edit", ["exports", "iris/utils/converter"], function (_exports, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    generatingPDF: false,
    generatingDeliveryNotePDF: false,
    hasCr: false,
    actions: {
      missingProposal: function () {
        const flashMessages = Ember.get(this, 'flashMessages');
        flashMessages.add({
          message: this.intl.t('projects.missingProposal'),
          title: 'IRIS message',
          icon: 'warning'
        });
      },

      generateOutlinePDF(model) {
        this.set('generatingPDF', true);
        const flashMessages = Ember.get(this, 'flashMessages');
        let token = this.get('session.data.authenticated.id');
        let adapter = this.store.adapterFor('application');
        let host = adapter.get('host');
        let namespace = adapter.get('namespace');
        let url = host + '/' + namespace + '/projects/' + model.id + '/pdf?access_token=' + token;

        var _this = this;

        this.get('ajax').downloadFile(url, function (status, statusText, response) {
          if (status === 200) {
            var arrayBuffer = response;
            var blob = new Blob([arrayBuffer], {
              type: 'application/octet-stream'
            });
            saveAs(blob, 'OutlinePDF.pdf');
          } else {
            flashMessages.add({
              message: 'Error in downloading...',
              title: 'IRIS message',
              icon: 'warning'
            });
          }

          _this.set('generatingPDF', false);
        });
      },

      async generateQuotationPDF(model, id) {
        this.set('generatingPDF', true);

        try {
          const base64 = await this.get('ajax').call('GET', 'project', model.id, id + '/pdf-quotation');
          saveAs(_converter.default.b64toBlob(base64.$data, 'application/pdf'), `quotation${moment().format('YYYY/MM/DD')}.pdf`);
        } catch (e) {
          const flashMessages = Ember.get(this, 'flashMessages');
          e.errors.forEach(err => {
            flashMessages.danger(err.detail, {
              title: this.intl.t('general.generation.error'),
              icon: 'warning',
              sticky: true
            });
          });
        } finally {
          this.set('generatingPDF', false);
        }
      }

    }
  });

  _exports.default = _default;
});