define("iris/components/kanban/action-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yUTaU+/o",
    "block": "{\"symbols\":[\"@icon\",\"@onClick\",\"@label\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"flex items-center gap-1 w-full btn-default px-4 py-2\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,2,[]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"fa-icon\",[[23,1,[]]],[[\"class\"],[\"mr-2 w-6\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"p\",true],[10,\"class\",\"text-lg\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/kanban/action-item/template.hbs"
    }
  });

  _exports.default = _default;
});