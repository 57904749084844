define("iris/models/incoming-invoice-item-allocation", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    amount: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    periodStart: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    periodEnd: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    file: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    invoice: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    invoiceItem: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    note: (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });

  var _default = _model.default.extend(Validations, {
    amount: (0, _attr.default)('number'),
    periodStart: (0, _attr.default)('date'),
    periodEnd: (0, _attr.default)('date'),
    note: (0, _attr.default)('string'),
    file: (0, _relationships.belongsTo)('project'),
    invoice: (0, _relationships.belongsTo)('incomingInvoice'),
    invoiceItem: (0, _relationships.belongsTo)('incomingInvoiceItem')
  });

  _exports.default = _default;
});