define("iris/components/modals/invoice-item-allocation/component", ["exports", "iris/lib/filters/storage", "bankers-rounding"], function (_exports, _storage, _bankersRounding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoiceItemAllocation = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _storage.useStorage)('memory'), _dec4 = Ember.computed.alias('data.proposalModelName'), _dec5 = Ember.computed.alias('data.invoice'), _dec6 = Ember.computed.alias('data.invoice.constructor.modelName'), _dec7 = Ember.computed.alias('invoice.currency'), _dec8 = Ember.computed('invoice.invoiceItemAllocations.@each.amount'), _dec9 = Ember.computed('invoice.items.@each.id'), _dec10 = Ember.computed('invoiceItemAllocationsLUT', 'itemsLUT'), _dec11 = Ember.computed('invoiceModelName'), _dec12 = Ember._action, _dec13 = Ember._action, (_class = class InvoiceItemAllocation extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "modals", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "proposalModelName", _descriptor4, this);

      _initializerDefineProperty(this, "invoice", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceModelName", _descriptor6, this);

      _initializerDefineProperty(this, "currency", _descriptor7, this);
    }

    get invoiceItemAllocationsLUT() {
      return this.invoice.get('invoiceItemAllocations').reduce((acc, allocation) => {
        const itemId = allocation.get('invoiceItem.id');

        if (!acc[itemId]) {
          acc[itemId] = 0;
        }

        acc[itemId] += Number(allocation.get('amount'));
        return acc;
      }, {});
    }

    get itemsLUT() {
      return this.invoice.get('items').reduce((acc, item) => {
        acc[item.id] = item.get('brutto');
        return acc;
      }, {});
    }

    get percentage() {
      const percentages = {};

      for (const itemId in this.itemsLUT) {
        const itemBrutto = this.itemsLUT[itemId];
        const amount = this.invoiceItemAllocationsLUT[itemId] || 0;
        percentages[itemId] = (0, _bankersRounding.default)(amount / itemBrutto * 100, this.currency.get('round'));
      }

      return percentages;
    }

    get dashedInvoiceItemAllocationModelName() {
      return this.invoiceModelName === 'invoice' ? 'invoice-item-allocation' : 'incoming-invoice-item-allocation';
    }

    getPeriodStart() {
      if (this.invoiceModelName === 'invoice') {
        return this.invoice.dateSettlement;
      } else {
        return this.invoice.dateEntered;
      }
    }

    async createRecord() {
      const record = this.store.createRecord(this.dashedInvoiceItemAllocationModelName);
      record.set('invoice', this.invoice);
      return record;
    }

    async addItem(item) {
      const invoice = item.get('invoice');
      const date = this.getPeriodStart();
      let file = invoice.get('project');

      if (!file) {
        const selectedFile = await this.modals.open('modals/invoice-item-allocation/select-file', {
          invoice
        });

        if (selectedFile === false) {
          return;
        }

        file = selectedFile;
      }

      const invoiceItemAllocation = this.store.createRecord(this.dashedInvoiceItemAllocationModelName, {
        invoice,
        invoiceItem: item,
        periodStart: date,
        periodEnd: date,
        amount: item.get('brutto'),
        file
      });
      const success = await invoiceItemAllocation.save();

      if (success) {
        this.flashMessages.info(this.intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'floppy-o'
        });
        this.storage.refresh();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "proposalModelName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "invoice", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceModelName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "invoiceItemAllocationsLUT", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "invoiceItemAllocationsLUT"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "itemsLUT", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "itemsLUT"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "percentage", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "percentage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dashedInvoiceItemAllocationModelName", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "dashedInvoiceItemAllocationModelName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createRecord", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "createRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addItem", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addItem"), _class.prototype)), _class));
  _exports.default = InvoiceItemAllocation;
});