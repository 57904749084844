define("iris/components/project/ticket/gantt/component", ["exports", "@kockpit/ember-gantt/utils/date-util", "iris/lib/modals", "leader-line-new", "jquery"], function (_exports, _dateUtil, _modals, _leaderLineNew, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _leaderLineNew.default.positionByWindowResize = false;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modals: Ember.inject.service(),
    projectsData: null,

    /**
     * @type {Array<LeaderLine>
     */
    lines: Ember.A(),
    goDate: null,
    viewStart: null,
    viewEnd: null,
    productResults: Ember.A(),
    dayWidth: 20,
    isDepLinesPainted: false,
    depLineObservers: Ember.A(),

    async init() {
      this._super(...arguments);

      const productResults = await this.loadProjectsData();
      this.set('productResults', productResults);

      const today = _dateUtil.default.getNewDate();

      let start = today;
      const childsStart = productResults.mapBy('plannedStartDate');

      if (childsStart.length) {
        const minStart = childsStart[0];
        start = _dateUtil.default.getNewDate(minStart);
      }

      Ember.set(this, 'viewStart', _dateUtil.default.datePlusDays(start, -30));
      Ember.set(this, 'viewEnd', _dateUtil.default.datePlusDays(today, 120));
      Ember.set(this, 'goDate', today);
      this.resetLineState();
    },

    resetLineState() {
      var _this$depLineObserver, _this$lines;

      if ((_this$depLineObserver = this.depLineObservers) !== null && _this$depLineObserver !== void 0 && _this$depLineObserver.length) {
        this.depLineObservers.forEach(observer => {
          try {
            observer.disconnect();
          } catch {
            observer = null;
          }
        });
        Ember.set(this, 'depLineObservers', Ember.A());
      }

      if ((_this$lines = this.lines) !== null && _this$lines !== void 0 && _this$lines.length) {
        this.lines.forEach(line => {
          try {
            line.remove();
          } catch {
            line = null;
          }
        });
        Ember.set(this, 'lines', Ember.A());
      }

      Ember.set(this, 'isDepLinesPainted', false);
    },

    // load initially projects and afterwards only data for newly expanding periods
    async loadProjectsData(start, end, expanded) {
      const prs = await this.get('store').query('fileProductResult', {
        filter: {
          where: {
            fileId: this.project.id
          },
          order: 'plannedStartDate'
        }
      });
      const filteredResults = prs.filter(pr => pr.plannedStartDate && pr.plannedEndDate);
      Ember.set(this, 'productResults', filteredResults);
      return filteredResults;
    },

    async initLines() {
      this.productResults.forEach(productResult => {
        const tickets = productResult.get('tickets');

        if (!(tickets !== null && tickets !== void 0 && tickets.length)) {
          return;
        }

        tickets.forEach(ticket => {
          const dependencyIds = ticket.get('dependencies').mapBy('id');

          if (!(dependencyIds !== null && dependencyIds !== void 0 && dependencyIds.length)) {
            return;
          }

          const ticketElement = document.getElementById(ticket.id);

          if (!ticketElement) {
            return;
          }

          dependencyIds.forEach(depId => {
            const dependencyElement = document.getElementById(depId);

            if (!dependencyElement) {
              return;
            }

            const line = new _leaderLineNew.default(ticketElement, dependencyElement, {
              color: 'red',
              size: 2,
              path: 'grid',
              endSocket: 'bottom',
              startSocket: 'left',
              startSocketGravity: 0,
              endSocketGravity: 0
            });
            const observer = new IntersectionObserver((entries, observer) => {
              if (!(entries !== null && entries !== void 0 && entries.length)) {
                return;
              }

              const entry = entries[0];

              if (!entry) {
                observer.disconnect();
                return;
              }

              if (entry.isIntersecting) {
                line.show();
                line.position();
              } else {
                line.hide();
              }
            }, {
              root: this.gantDOM[0],
              rootMargin: '-100px',
              threshold: 0
            });
            observer.observe(dependencyElement);
            this.depLineObservers.pushObject(observer);
            this.lines.push(line);
          });
        });
      });

      if (this.lines.length) {
        Ember.set(this, 'isDepLinesPainted', true);
      }
    },

    async didInsertElement() {
      this._super(...arguments);

      this.gantDOM = (0, _jquery.default)('.gantt-chart-inner');
    },

    async didRender() {
      var _this$productResults;

      this._super(...arguments);

      if (this.isDepLinesPainted) {
        this.lines.forEach(line => line.position());
        return;
      }

      if (this.lines.length || !((_this$productResults = this.productResults) !== null && _this$productResults !== void 0 && _this$productResults.length)) {
        return;
      }

      this.initLines();
    },

    willDestroyElement() {
      this.resetLineState();

      this._super(...arguments);
    },

    actions: {
      // ToDo - Implement
      zoom(value) {
        let newDayWidth = Math.max(1, parseInt(Ember.get(this, 'dayWidth')) + parseInt(value));
        Ember.set(this, 'dayWidth', newDayWidth);
        Ember.set(this, 'goDate', _dateUtil.default.datePlusDays(this.get('goDate'), 1));
      },

      async addNewProductResult() {
        const record = this.store.createRecord('fileProductResult');
        this.project['productResults'].pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: 'productResults',
          parentModel: this.project,
          neededFields: ['name', 'plannedStartDate', 'plannedEndDate', 'billable']
        }, {
          className: _modals.ModalLayoutClassName['M']
        });

        if (newItem) {
          this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
        }
      },

      async addNewTicket(productResult) {
        const record = this.store.createRecord('ticket', {
          productResult,
          plannedStartDate: productResult.plannedStartDate,
          plannedEndDate: productResult.plannedEndDate
        });
        this.project['tickets'].pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: 'tickets',
          parentModel: this.project,
          formComponent: 'forms/ticket-form'
        }, {
          className: _modals.ModalLayoutClassName['XXL']
        });

        if (newItem) {
          record.setStartEndDates();
          this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
        }
      },

      async openProductResult(productResult) {
        let editItem = await this.modals.open(`modals/form-modal`, {
          model: productResult,
          itemName: 'productResults',
          parentModel: this.project,
          neededFields: ['name', 'plannedStartDate', 'plannedEndDate', 'billable']
        }, {
          className: _modals.ModalLayoutClassName['M']
        });
        this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
      },

      async openTicket(ticket) {
        let editItem = await this.modals.open(`modals/form-modal`, {
          model: ticket,
          itemName: 'tickets',
          parentModel: this.project,
          formComponent: 'forms/ticket-form'
        }, {
          className: _modals.ModalLayoutClassName['XXXL']
        });
        this.loadProjectsData(Ember.get(this, 'viewStart'), Ember.get(this, 'viewEnd'), '');
      },

      onDateChange(ticket) {
        ticket.save();
        ticket.setStartEndDates();
      },

      goToDate(ticket) {
        const date = _dateUtil.default.getNewDate(ticket.get('plannedStartDate')); // scroll-to today if date not passed


        this.set('goDate', date);
      },

      /**
       * @param {Date}   newStartDate    new total view start date
       * @param {Date}   newEndDate      new total view end date
       * @param {string} expaned         { 'after' | 'before' }
       * @param {Date}   previousStart   previous start date
       * @param {Date}   previousEnd     previous end date
       */
      loadProjects(newStartDate, newEndDate, expanded, previousStart, previousEnd) {
        /*
        Ez akkor kellene, ha nem töltenénk be az egész projectData-t
        // expand before
        if (expanded === 'before') {
          this.loadProjectsData(newStartDate, previousStart, expanded);
         // expand after
        } else {
          this.loadProjectsData(previousEnd, newEndDate, expanded);
        }
        */
      }

    }
  });

  _exports.default = _default;
});