define("iris/components/issue-report/modals/work-log/component", ["exports", "iris/utils/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IssueReportModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = class IssueReportModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor4, this);
    }

    async validateHourLimits() {
      const {
        data: {
          workLog
        },
        sessionAccount: {
          account
        }
      } = this;
      const beginDate = workLog.get('beginDate');
      const workLogs = await this.api.query('workLog', {
        filter: {
          where: {
            beginDate: {
              between: [(0, _moment.default)(beginDate).startOf('day').toDate(), (0, _moment.default)(beginDate).endOf('day').toDate()]
            },
            userId: account.id
          }
        }
      });
      const alreadRegisteredHours = workLogs.reduce((acc, workLog) => {
        acc += workLog.get('publicSpentTime') + workLog.get('spentTime');
        return acc;
      }, 0);
      const hoursToRegister = workLog.get('spentTime') + workLog.get('publicSpentTime');
      const maxHoursPerWorkLog = workLog.get('ticket').get('maxHoursPerWorkLog');

      if (hoursToRegister > maxHoursPerWorkLog) {
        this.flashMessages.danger(this.intl.t('work-log-error.max-hours-per-day-ticket', {
          alreadyRegistred: alreadRegisteredHours,
          registred: hoursToRegister,
          maxHoursPerWorkLog: maxHoursPerWorkLog
        }), {
          title: this.intl.t('general.error'),
          icon: 'warning',
          sticky: true
        });
        return false;
      }

      if (alreadRegisteredHours + hoursToRegister > account.maxRegistredHours) {
        this.flashMessages.danger(this.intl.t('work-log-error.max-hours-per-day', {
          alreadyRegistred: alreadRegisteredHours,
          registred: hoursToRegister,
          maxRegistredHours: account.maxRegistredHours
        }), {
          title: this.intl.t('general.error'),
          icon: 'warning',
          sticky: true
        });
        return false;
      }

      return true;
    }

    async validateWorkLog() {
      const {
        workLog
      } = this.data;
      let {
        validations
      } = await (0, _model.validateRecord)(workLog);
      workLog.setValidated();

      if (!validations.isValid) {
        return;
      }

      const result = await this.validateHourLimits();

      if (result === false) {
        return;
      }

      this.close(true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validateWorkLog", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "validateWorkLog"), _class.prototype)), _class));
  _exports.default = IssueReportModal;
});