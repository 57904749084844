define("iris/components/forms/ticket-overview/component", ["exports", "iris/lib/modals", "ember-concurrency", "iris/utils/ember-concurrency", "moment"], function (_exports, _modals, _emberConcurrency, _emberConcurrency2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    filteredTicketStates: Ember.computed.filterBy('ticketStates', 'project'),

    init() {
      this._super(...arguments);

      this.dependentTicketOptionsTask.perform();
    },

    /** Fetch all tickets that are:
     * - in the same project with the current ticket
     * - not deleted
     * - not the current ticket
     * - not dependent on the current ticket or in a dependency loop with it
     * - end date is null or earlier than the current ticket's start date (= already finished)
     */
    dependentTicketOptionsTask: (0, _emberConcurrency.task)(function* (query) {
      var _this$model$get;

      const currentTicketId = this.model.get('id');
      let currentTicketStartDate = (_this$model$get = this.model.get('plannedStartDate')) !== null && _this$model$get !== void 0 ? _this$model$get : null;

      if (currentTicketStartDate && _moment.default.isMoment(currentTicketStartDate)) {
        currentTicketStartDate = currentTicketStartDate.toDate();
      }

      const {
        store
      } = this;
      /**
       * Recursively check if the current ticket is a dependency of any of the given dependencies
       * @returns {Promise<boolean>} true if the current ticket is a dependency of any of the given dependencies, false otherwise
       */

      const recursiveDependencyLoopDetection = async dependencyIds => {
        const nestedDependencies = await store.query('ticket', {
          filter: {
            where: {
              and: [{
                id: {
                  inq: dependencyIds
                }
              }, {
                deletedAt: null
              }]
            },
            include: 'dependencies'
          }
        });

        if (!nestedDependencies || nestedDependencies.length === 0) {
          return false;
        }

        const nestedDependencyIds = nestedDependencies.map(ticket => ticket.get('dependencies').mapBy('id')).flat();

        if (!nestedDependencyIds || nestedDependencyIds.length === 0) {
          return false;
        }

        if (nestedDependencyIds.includes(currentTicketId)) {
          return true;
        }

        return recursiveDependencyLoopDetection(nestedDependencyIds);
      };

      return yield store.query('ticket', {
        filter: {
          where: {
            and: [{
              fileId: this.model.file.get('id')
            }, {
              id: {
                neq: currentTicketId
              }
            }, {
              deletedAt: null
            }, {
              or: [{
                plannedEndDate: null
              }, {
                plannedEndDate: {
                  lte: currentTicketStartDate
                }
              }]
            }]
          },
          include: 'dependencies'
        }
      }).then(tickets => {
        const filteredTickets = tickets.filter(async ticket => {
          const dependencyIds = ticket.get('dependencies').mapBy('id');

          if (!dependencyIds || dependencyIds.length === 0) {
            return true;
          }

          if (dependencyIds.includes(currentTicketId)) {
            return false;
          }

          const nestedDetection = await recursiveDependencyLoopDetection(dependencyIds);
          return !nestedDetection;
        });
        return filteredTickets;
      });
    }).restartable(),
    dependentTicketOptions: (0, _emberConcurrency2.taskValue)('dependentTicketOptionsTask'),
    minStartDate: Ember.computed('model.dependencies.[]', function () {
      const dependencies = this.model.dependencies || [];

      if (!dependencies.length) {
        return null;
      } // max end date of dependencies


      return dependencies.reduce((max, _ref) => {
        let {
          plannedEndDate
        } = _ref;
        const endDate = (0, _moment.default)(plannedEndDate);
        return endDate.isAfter(max) ? endDate : max;
      }, (0, _moment.default)(dependencies.firstObject.plannedEndDate));
    }),
    maxEndDate: Ember.computed('model.dependents.[]', function () {
      const dependents = this.model.dependents || [];

      if (!dependents.length) {
        return null;
      } // min start date of tasks that depend on this task


      return dependents.reduce((min, _ref2) => {
        let {
          plannedStartDate
        } = _ref2;
        const startDate = (0, _moment.default)(plannedStartDate);
        return startDate.isBefore(min) ? startDate : min;
      }, (0, _moment.default)(dependents.firstObject.plannedStartDate));
    }),
    actions: {
      async changeTicketState(state) {
        if (state.get('id') === 'SYS_07' && this.model.hasNotCompletedDependency) {
          // SYS_07 = megoldott/solved
          this.flashMessages.danger(this.intl.t('ticket.stateDependencyError', {
            dependencyName: this.model.notCompletedDependencies.map(ncd => ncd.subject).join(',')
          }), {
            title: this.intl.t('general.error'),
            icon: 'warning'
          });
          return;
        }

        this.model.set('state', state);
      },

      async changePlannedStartDate(momentDate) {
        const date = momentDate.toDate();
        this.model.set('plannedStartDate', date);
        this.dependentTicketOptionsTask.perform();
      },

      async changePlannedEndDate(momentDate) {
        const date = momentDate.toDate();
        this.model.set('plannedEndDate', date);
        this.dependentTicketOptionsTask.perform();
      },

      async changeProductResult(productResult) {
        const model = this.model;
        model.set('productResult', productResult);

        if (Ember.isEmpty(model.plannedStartDate) && Ember.isEmpty(model.plannedEndDate)) {
          model.set('plannedStartDate', productResult.get('plannedStartDate'));
          model.set('plannedEndDate', productResult.get('plannedEndDate'));
        }
      },

      async addNew() {
        const record = this.store.createRecord('fileProductResult');
        this.model.get('file.productResults').pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: 'productResults',
          parentModel: this.model.file,
          neededFields: ['name', 'plannedStartDate', 'plannedEndDate', 'billable']
        }, {
          className: _modals.ModalLayoutClassName['M']
        });
        this.send('changeProductResult', record);
      },

      uploadFile() {
        const self = this;
        this.modals.open('modals/file-upload-promise', {
          model: self.model,
          only: 'Bejl'
        }, {
          className: _modals.ModalLayoutClassName['L']
        });
      }

    }
  });

  _exports.default = _default;
});