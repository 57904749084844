define("iris/components/tables/projects/component", ["exports", "iris/lib/filters/types", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/table", "iris/utils/flatten-eq-operators", "iris/lib/modals"], function (_exports, _types, _filters, _column, _table, _flattenEqOperators, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TablesProject = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed, _dec7 = Ember.computed('columns', 'storage.params', 'changes'), _dec8 = (0, _column.columns)(function () {
    const columns = [{
      name: 'closed',
      displayName: this.intl.t('filters.closed'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.Boolean,

      formatLoopBackValue(value, filter, defaultFormatter) {
        if (filter.filterType === _types.FilterType.Boolean) {
          return value ? 1 : 0;
        }

        return defaultFormatter(value);
      }

    }, {
      name: 'fileNumber',
      displayName: this.intl.t('project.fileNumber'),
      fixed: true,
      route: 'projects.files.edit',
      routeParams: (0, _table.rowValues)('id')
    }, {
      name: 'name',
      displayName: this.intl.t('project.name')
    }, {
      name: 'partner',
      displayName: this.intl.t('project.clientPartner'),
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'p.name',
      displayName: this.intl.t('project.clientPartner'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'p.shortName',
      displayName: this.intl.t('project.clientPartner'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'primeContractorName',
      displayName: this.intl.t('project.primeContractor'),
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'p2.name',
      displayName: this.intl.t('project.primeContractor'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'p2.shortName',
      displayName: this.intl.t('project.primeContractor'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'clientPartnerId',
      displayName: this.intl.t('project.clientPartner'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'partner'
    }, {
      name: 'fileTypeId',
      displayName: this.intl.t('models.operationalFile.type'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'fileType'
    }, {
      name: 'ft.name',
      displayName: this.intl.t('models.operationalFile.type'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'fileOwnerPartnerId',
      displayName: this.intl.t('project.fileOwnerPartnerCompany'),
      type: _types.AttributeType.BelongsTo,
      valuePath: 'fileOwnerPartnerName',
      orderProperty: 'fileOwnerPartnerName',
      optionsResourceName: 'partner',
      optionsQuery: async () => {
        return await this.store.query('partner', {
          filter: {
            where: {
              multiOwnerPartner: true
            }
          }
        });
      }
    }, {
      name: 'fileBusinessTypeId',
      displayName: this.intl.t('file-type.fileBusinessType'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'fileBusinessType'
    }, {
      name: 'primeContractorId',
      displayName: this.intl.t('project.primeContractor'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'partner'
    }, {
      name: 'stateName',
      displayName: this.intl.t('project.fileState'),
      visibility: _types.AttributeVisibility.Table,
      resizable: false
    }, {
      name: 'orderNumber',
      displayName: this.intl.t('project.orderNumber')
    }, {
      name: 'fileStateId',
      displayName: this.intl.t('project.fileState'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'fileState'
    }, {
      name: 'semaphores',
      displayName: '',
      sortable: false,
      exportable: false,
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.SM
    }, {
      name: 'investmentPlanned',
      displayName: this.intl.t('project.investmentPlanned'),
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.XS
    }, {
      name: 'investmentActual',
      displayName: this.intl.t('project.fileNumber'),
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.XS
    }, {
      name: 'completionLevel',
      displayName: this.intl.t('project.completionLevel'),
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.XS
    }, {
      name: 'onTimeInfo',
      displayName: '',
      visibility: _types.AttributeVisibility.Table,
      sortable: false,
      exportable: false
    }, {
      name: 'onTimePercent',
      displayName: this.intl.t('project.onTimePercent'),
      visibility: _types.AttributeVisibility.Table,
      sortable: false,
      width: _column.ColumnWidth.XS
    }, {
      name: 'onTimePerOnGoal',
      displayName: this.intl.t('project.onTimePerOnGoal'),
      visibility: _types.AttributeVisibility.Table,
      sortable: false,
      width: _column.ColumnWidth.XS
    }, {
      name: 'onBudgetInHourPerOnGoal',
      displayName: this.intl.t('project.onBudgetInHourPerOnGoal'),
      visibility: _types.AttributeVisibility.Table,
      sortable: false,
      width: _column.ColumnWidth.XS
    }, {
      name: 'onBudgetInValuePerOnGoal',
      displayName: this.intl.t('project.onBudgetInValuePerOnGoal'),
      visibility: _types.AttributeVisibility.Table,
      sortable: false,
      width: _column.ColumnWidth.XS
    }, {
      name: 'onBudgetInHour',
      displayName: this.intl.t('project.onBudgetInHour'),
      visibility: _types.AttributeVisibility.Table,
      sortable: false,
      width: _column.ColumnWidth.XS
    }, {
      name: 'onBudgetInValue',
      displayName: this.intl.t('project.onBudgetInValue'),
      visibility: _types.AttributeVisibility.Table,
      sortable: false,
      width: _column.ColumnWidth.XS
    }, {
      name: 'managerUserId',
      displayName: this.intl.t('project.managerUser'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'account'
    }, {
      name: 'sponsorUserId',
      displayName: this.intl.t('project.sponsorUser'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.BelongsTo,
      optionsResourceName: 'account'
    }, {
      name: 'subcontractor',
      displayName: this.intl.t('project.subcontractor'),
      visibility: _types.AttributeVisibility.Filter,
      type: _types.AttributeType.Boolean,

      formatLoopBackValue(value, filter, defaultFormatter) {
        if (filter.filterType === _types.FilterType.Boolean) {
          return value ? 1 : 0;
        }

        return defaultFormatter(value);
      }

    }, {
      name: 'accountable',
      displayName: this.intl.t('project.accountable'),
      visibility: _types.AttributeVisibility.Filter,
      width: _column.ColumnWidth.XS,
      type: _types.AttributeType.Boolean
    }];

    if (this.regionElement.show('ticket-pst')) {
      const pstFilter = {
        name: 'tickets.pst',
        displayName: 'PST',
        visibility: _types.AttributeVisibility.Filter
      };
      columns.push(pstFilter);
    }

    return columns;
  }), _dec9 = (0, _table.rowActions)(function () {
    const {
      flashMessages,
      intl,
      constraintsRelations
    } = this;
    return [{
      name: this.intl.t('project.clone'),
      icon: 'clone',

      async action(project) {
        let shouldClone = await this.modals.open('beta/modals/confirmation', {
          title: this.intl.t('project.clone'),
          body: this.intl.t('project.clone-info')
        });

        if (shouldClone) {
          try {
            let clonedProject = await this.api.memberAction('project', 'clone', project.id, {
              method: 'POST'
            });
            this.flashMessages.info(this.intl.t('project.new-project-title'), {
              title: this.intl.t('general.clone.success'),
              icon: 'copy',
              sticky: true
            });
            this.router.transitionTo('crm.opportunities.edit', clonedProject.id);
            this.flashMessages.warning(this.intl.t('project.clone-location'), {
              title: this.intl.t('general.warning'),
              icon: 'warning',
              sticky: true
            });
          } catch (error) {
            this.flashMessages.danger(this.intl.t('general.clone.error'), {
              title: this.intl.t('general.error'),
              icon: 'warning',
              sticky: true
            });
          }
        }
      }

    }, {
      name: intl.t('general.deletion'),
      icon: 'trash',
      style: 'danger',
      disabled: Ember.computed('row', function () {
        for (const relName of constraintsRelations) {
          const ids = this.row[relName];

          if (ids && ids.length > 0) {
            return intl.t('general.delete.cant-delete-constraint', {
              relation: intl.t(`project.${relName}`),
              qty: ids.length
            });
          }
        }

        return false;
      }),

      async action(row) {
        const shouldDelete = await this.modals.open('beta/modals/delete-confirmation');
        if (!shouldDelete) return;
        const record = await this.store.findRecord('project', row.id);
        const res = await record.destroyRecord(true);

        if (res) {
          this.storage.refresh();
          flashMessages.info(intl.t('general.delete.success'), {
            title: intl.t('general.delete.success-title'),
            icon: 'times'
          });
        } else {
          flashMessages.danger(this.intl.t('general.delete.error'), {
            title: this.intl.t('general.delete.error'),
            icon: 'warning'
          });
        }
      }

    }];
  }), _dec10 = Ember._action, (_class = class TablesProject extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _initializerDefineProperty(this, "regionElement", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "api", _descriptor5, this);

      _defineProperty(this, "changes", false);

      _defineProperty(this, "constraintsRelations", []);

      _initializerDefineProperty(this, "columns", _descriptor6, this);

      _initializerDefineProperty(this, "rowActions", _descriptor7, this);

      const tempProject = this.store.createRecord('project', {
        type: 'project'
      });
      const constraintsRelations = [];
      tempProject.eachRelationship((name, descriptor) => {
        var _descriptor$meta$opti;

        if ((_descriptor$meta$opti = descriptor.meta.options) !== null && _descriptor$meta$opti !== void 0 && _descriptor$meta$opti.constraints) {
          constraintsRelations.push(name);
        }
      });
      this.set('constraintsRelations', constraintsRelations);
    }

    get predefinedView() {
      return [{
        name: this.intl.t('general.all'),
        filters: []
      }, {
        name: this.intl.t('general.open'),
        filters: [{
          name: 'closed',
          type: _types.FilterType.Boolean,
          value: false
        }]
      }];
    }

    get fetchParams() {
      const queryObject = (0, _filters.makeQueryObject)(this.columns, this.storage.params, {});
      queryObject.filter.where.and.push({
        isActive: 1
      }, {
        type: 'normal'
      }, {
        probability: {
          eq: 100
        }
      });
      let crVersion = this.changes ? {
        crVersion: -1
      } : {
        or: [{
          crVersion: null
        }, {
          crVersion: 0
        }]
      };
      queryObject.filter.where.and.push(crVersion);
      (0, _flattenEqOperators.flattenEqOperators)(queryObject.filter.where);
      return queryObject;
    }

    async importTSM() {
      await this.modals.open('modals/import-tsm', {}, {
        className: _modals.ModalLayoutClassName.XS
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "regionElement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "predefinedView", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "predefinedView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchParams", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "fetchParams"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "rowActions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "importTSM", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "importTSM"), _class.prototype)), _class));
  _exports.default = TablesProject;
});