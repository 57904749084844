define("iris/components/forms/invoice-item-allocation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ea+55P70",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],\"file\"]]],[0,\"\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@searchProperty\",\"@order\",\"@action\",\"@where\"],[[23,1,[]],\"invoiceItem\",\"description\",\"description\",[28,\"action\",[[23,0,[]],\"changeItem\"],null],[23,0,[\"where\"]]]]],[0,\"\\n\\n\"],[5,\"form-group\",[],[[\"@label\",\"@property\"],[[28,\"t\",[\"invoice-item-allocation.amount\"],null],\"amount\"]],{\"statements\":[[0,\"\\n  \"],[5,\"number-input\",[],[[\"@value\",\"@mask\"],[[23,1,[\"amount\"]],\"[-]9{*}.{*}9{*}\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],\"periodStart\"]]],[0,\"\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],\"periodEnd\"]]],[0,\"\\n\"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\"],[[23,1,[]],\"note\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/invoice-item-allocation/template.hbs"
    }
  });

  _exports.default = _default;
});