define("iris/components/modals/invoice-item-allocation/select-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s6GafH8S",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[23,1,[\"invoice\",\"accountNumber\"]],false],[0,\"\\n      \"],[1,[28,\"t\",[\"components.invoice-item-allocation.no-file-title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"italic mb-10\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"components.invoice-item-allocation.no-file\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@selected\",\"@onchange\"],[\"project\",[23,0,[\"selectedFile\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"selectedFile\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"primary\",[28,\"fn\",[[23,2,[]],[23,0,[\"selectedFile\"]]],null],[28,\"t\",[\"general.select\"],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[28,\"t\",[\"buttons.close\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/invoice-item-allocation/select-file/template.hbs"
    }
  });

  _exports.default = _default;
});