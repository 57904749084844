define("iris/components/forms/invoice-item-allocation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoiceItemAllocation = (_dec = Ember.computed.alias('data.invoice'), _dec2 = Ember.computed.alias('data.invoice.constructor.modelName'), _dec3 = Ember.computed('invoiceModelName'), _dec4 = Ember._action, (_class = class InvoiceItemAllocation extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "invoice", _descriptor, this);

      _initializerDefineProperty(this, "invoiceModelName", _descriptor2, this);
    }

    get where() {
      return this.invoiceModelName === 'invoice' ? {
        invoiceId: this.invoice.id
      } : {
        incomingInvoiceId: this.invoice.id
      };
    }

    changeItem(item) {
      const alreadyAllocated = this.invoice.invoiceItemAllocations.reduce((acc, allocation) => {
        const itemId = allocation.belongsTo('invoiceItem').id();

        if (itemId === item.id) {
          acc += Number(allocation.get('amount'));
        }

        return acc;
      }, 0);
      const itemBrutto = this.invoice.items.find(invoiceItem => invoiceItem.id === item.id).get('brutto');
      const amount = Number(itemBrutto) - alreadyAllocated;
      this.model.set('invoiceItem', item);
      this.model.set('amount', amount);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "invoice", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoiceModelName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "where", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "where"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeItem"), _class.prototype)), _class));
  _exports.default = InvoiceItemAllocation;
});