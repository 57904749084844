define("iris/services/store", ["exports", "ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _store.default.extend({
    sessionAccount: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    createRecord(modelName, inputProperties) {
      let record = this._super(modelName, inputProperties);

      const disableDefault = inputProperties && inputProperties.disableDefault;

      if (disableDefault) {
        return record;
      } // Set default values from regional settings


      const flashMessages = this.get('flashMessages');
      this.query('regionalSetting', {
        filter: {
          where: {
            regionId: this.get('sessionAccount.region.id'),
            settingKey: {
              like: `${modelName}.%.default`
            }
          }
        }
      }).then(rss => {
        const res = {};
        Promise.all(rss.toArray().map(async element => {
          let key = element.settingKey.split('.')[1];
          res[key] = element.settingValue;
          const relationship = record.relationshipFor(key);

          if (relationship && relationship.kind === 'belongsTo') {
            const relatedRecord = await this.findRecord(relationship.type, res[key]);
            res[key] = relatedRecord;
          } else if (relationship && relationship.kind === 'hasMany') {
            const relatedRecords = await this.query(relationship.type, {
              filter: {
                where: {
                  id: {
                    inq: res[key].split(',')
                  }
                }
              }
            });
            res[key] = relatedRecords;
          }
        })).then(() => {
          record.setProperties(res);
        });
      }).catch(err => {
        let errorMessages = '';

        if (err.errors) {
          err.errors.forEach(error => {
            errorMessages += error.detail + '.';
          });
        } else {
          errorMessages += err;
        }

        flashMessages.add({
          message: errorMessages,
          title: 'IRIS message',
          icon: 'warning'
        });
      }); // burnt-in

      if (modelName === 'ticket' || modelName === 'ticketTemplate') {
        const preTicket = inputProperties && inputProperties.preTicket;
        const draftTicket = inputProperties && inputProperties.draftTicket;
        const scheduledTaskTemplate = inputProperties && inputProperties.scheduledTaskTemplate;
        let ticketStateId = null;

        if (preTicket) {
          ticketStateId = 'SYS_17';
        } else if (draftTicket) {
          ticketStateId = 'SYS_19';
        } else {
          ticketStateId = 'SYS_02';
        }

        const operational = inputProperties && inputProperties.oper;
        record.set('operational', operational);
        this.find('ticketState', ticketStateId).then(state => {
          record.set('state', state);
        });

        if (!preTicket && !draftTicket) {
          let typeId = operational ? 'SYS_16' : 'SYS_02';
          let incident = inputProperties && inputProperties.incident;

          if (incident) {
            typeId = 'SYS_01';
          }

          let accessRequest = inputProperties && inputProperties.accessRequest;

          if (accessRequest) {
            typeId = 'SYS_17';
          } // If it is a scheduled task template, leave the type empty


          if (!scheduledTaskTemplate) {
            this.find('ticketType', typeId).then(type => {
              record.set('type', type);
            });
          }
        }

        let account = this.get('sessionAccount.account');
        account.get('userGrade').then(userGrade => {
          record.set('plannedUserGrade', userGrade);
        });

        if (operational) {
          this.find('ticketSource', '000').then(source => {
            record.set('ticketSource', source);
          });
          record.addInterventionDatas();
        }
      } else if (modelName === 'account') {
        this.query('competency', {
          filter: {
            where: {
              name: '__DomainEveryone'
            }
          }
        }).then(competencies => {
          record.set('competencies', competencies);
        });
      }

      if (modelName === 'project') {
        this.project(record, inputProperties);
      }

      if (modelName === 'address') {
        this.address(record);
      }

      if (modelName === 'partnerBankcode') {
        this.partnerBankcode(record);
      }

      if (modelName === 'entity') {
        this.entity(record);
      }

      if (modelName === 'fileProposal') {
        this.proposal(record);
      }

      if (modelName === 'fileProposalItem') {
        this.proposalItem(record);
      }

      if (modelName === 'invoiceItem') {
        this.invoiceItem(record, inputProperties);
      }

      return record;
    },

    async entity(record) {
      let region = this.get('sessionAccount.region');
      let ownerPartner;

      if (!this.get('sessionAccount.account.portal')) {
        ownerPartner = await region.get('ownerPartner');
      } else {
        ownerPartner = await this.get('sessionAccount.account.contact.partner');
      }

      record.set('partner', ownerPartner);
      let siteZones = await ownerPartner.get('siteZones');
      siteZones.forEach(siteZone => {
        if (siteZone.get('default')) {
          record.set('siteZone', siteZone);
        }
      });
    },

    partnerBankcode(record) {
      let region = this.get('sessionAccount.region');
      region.get('ownerPartner').then(ownerPartner => {
        ownerPartner.get('currencyPayment').then(currency => {
          record.set('currencyPayment', currency);
        });
      });
    },

    address(record) {
      this.find('country', 'SYS_01').then(country => {
        record.set('country', country);
      });
    },

    proposal(record) {
      let region = this.get('sessionAccount.region');
      region.get('ownerPartner').then(ownerPartner => {
        ownerPartner.get('currencyPayment').then(currency => {
          record.set('currency', currency);
        });
      });
    },

    proposalItem(record) {
      this.find('vat', 'SYS_03').then(vat => {
        record.set('vat', vat);
      });
      this.find('uom', 'SYS_01').then(uom => {
        record.set('uom', uom);
      });
    },

    project(record, inputProperties) {
      let store = this; // set default sponsor

      let region = this.get('sessionAccount.region');
      let where = {
        and: [{
          regionId: region.id
        }, {
          settingKey: 'sponsor'
        }]
      };
      store.query('regionalSetting', {
        filter: {
          where: where
        }
      }).then(settings => {
        settings.forEach(function (setting) {
          store.find('account', setting.get('settingValue')).then(sponsor => {
            record.set('sponsorUser', sponsor);
          });
        });
      });
      record.set('managerUser', this.get('sessionAccount.account'));
      record.set('salesManager', this.get('sessionAccount.account'));
      record.set('technicalManagerUser', this.get('sessionAccount.account'));
    },

    async invoiceItem(record, inputProperties) {
      if (inputProperties !== null && inputProperties !== void 0 && inputProperties.fromCompletionCert) {
        return;
      }

      const vats = await this.query('vat', {
        filter: {
          where: {
            systemCode: 1
          }
        }
      });
      const defaultVat = vats.get('firstObject');

      if (!defaultVat) {
        return;
      }

      record.set('vatRef', defaultVat);
      record.set('vat', defaultVat.get('vatValue'));
    }

  });

  _exports.default = _default;
});