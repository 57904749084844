define("iris/models/file-outgoing-proposal-template", ["exports", "ember-data/model", "ember-data/attr", "ember-data-copyable"], function (_exports, _model, _attr, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_emberDataCopyable.default, {
    name: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    resourceKey: (0, _attr.default)('string')
  });

  _exports.default = _default;
});