define("iris/components/modals/select-project-ticket-template-group/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SelectProjectTicketTemplateGroupModal = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('projectTicketTemplateGroup.projectTicketTemplates.length'), _dec5 = Ember.computed.alias('data.isOperational'), _dec6 = Ember.computed.mapBy('productResults', 'name'), _dec7 = Ember._action, _dec8 = Ember._action, (_class = class SelectProjectTicketTemplateGroupModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "projectTicketTemplateGroup", null);

      _defineProperty(this, "createNewProductResult", false);

      _defineProperty(this, "overwriteProductResultName", false);

      _defineProperty(this, "productResults", []);

      _initializerDefineProperty(this, "productResultName", _descriptor, this);

      _initializerDefineProperty(this, "productResultNameError", _descriptor2, this);

      _defineProperty(this, "PRODUCT_NAME_MAX_LENGTH", 512);

      _initializerDefineProperty(this, "api", _descriptor3, this);

      _initializerDefineProperty(this, "ticketCount", _descriptor4, this);

      _initializerDefineProperty(this, "isOperational", _descriptor5, this);

      _initializerDefineProperty(this, "productResultNames", _descriptor6, this);
    }

    async didReceiveAttrs() {
      await this.loadProductResults.perform();
    }

    *loadProductResults() {
      const productResult = yield this.api.query('fileProductResult', {
        filter: {
          where: {
            fileId: this.data.fileId
          }
        }
      });
      this.set('productResults', productResult);
    }

    validateProductResultName(name) {
      const trimmedName = (name === null || name === void 0 ? void 0 : name.trim()) || '';

      if (!trimmedName) {
        return 'validators.presence';
      }

      if (this.productResultNames.includes(trimmedName)) {
        return 'projects.files.product-result-already-exists';
      }

      if (trimmedName.length > this.PRODUCT_NAME_MAX_LENGTH) {
        return 'projects.files.product-result-name-too-long';
      }

      return null;
    }

    setProductResultName(name) {
      const error = this.validateProductResultName(name);
      this.productResultNameError = error || '';

      if (!error) {
        this.productResultName = name.trim();
      }
    }

    setProjectTicketTemplateGroup(selected) {
      this.set('projectTicketTemplateGroup', selected);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "productResultName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "productResultNameError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ticketCount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isOperational", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "productResultNames", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadProductResults", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadProductResults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProductResultName", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setProductResultName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProjectTicketTemplateGroup", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setProjectTicketTemplateGroup"), _class.prototype)), _class));
  _exports.default = SelectProjectTicketTemplateGroupModal;
});